<template>
  <div class="je-modifyinfo">
    <div class="box_top">
      <Header ref="editUserInfo" />
    </div>
    <div class="modifyinfo">
      <div class="tips">
        感谢选择JEPaaS平台，请完善下方信息，我们的销售将会为您提供定向的专业服务。如有问题，也可致电：400-0999-235。
      </div>
      <div class="cline" />
      <el-container>
        <el-aside>
          <div class="headerInfo">
            <img
              v-if="imageUrl"
              :src="imageUrl"
            >
            <span v-else>{{ userInfo_get.userName && userInfo_get.userName.substring(userInfo_get.userName.length-2) }}</span>
          </div>
          <el-upload
            :action="uploadUrl"
            :data="uploadData"
            :show-file-list="false"
            :with-credentials="true"
            :headers="token"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
            class="avatar-uploader"
          >
            <div
              @mouseover="mouseOverPhoto"
              @mouseleave="mouseLeavePhoto"
              class="mdyPhoto"
            >
              {{ photoShow && '修改头像' || '' }}
            </div>
          </el-upload>
          <div
            v-html="ruleForm.name"
            class="nameInfo"
          />
          <!-- <div class="menulist" style="margin-top: 40px;">个人信息</div>
          <div class="menulist">企业信息</div>-->
        </el-aside>
        <el-main>
          <el-form
            ref="ruleForm"
            :model="ruleForm"
            :rules="rules"
            label-width="100px"
            class="info-ruleForm"
          >
            <el-form-item
              label="姓名"
              prop="name"
            >
              <el-input
                v-model="ruleForm.name"
                maxlength="10"
              />
            </el-form-item>
            <el-form-item label="密码">
              <el-input
                v-model="ruleForm.password"
                :type="passw"
                placeholder="密码"
                autocomplete="off"
                readonly
                class="pass"
              >
                <el-button
                  slot="append"
                  @click="modifyPsd"
                  type
                >
                  修改密码
                </el-button>
              </el-input>
            </el-form-item>
            <el-form-item
              label="邮箱"
              prop="email"
            >
              <el-input
                v-model="ruleForm.email"
                type="email"
              />
            </el-form-item>
            <el-form-item label="手机号">
              <el-input
                v-model="ruleForm.phone"
                class="pass"
                readonly
                placeholder="手机号"
                type="phone"
                autocomplete="off"
              >
                <el-button
                  slot="append"
                  @click="modifyPhone"
                  type="text"
                >
                  更换手机号
                </el-button>
              </el-input>
            </el-form-item>
            <el-form-item
              label="所在城市"
              class="szcsitem"
            >
              <el-select
                v-model="ruleForm.provinceValue"
                @change="provinceChange"
                class="szdSelect"
                placeholder="请选择省"
              >
                <el-option
                  v-for="(item, _province) in province"
                  :key="_province"
                  :label="item.label"
                  :value="_province"
                />
              </el-select>
              <el-select
                v-model="ruleForm.cityValue"
                @change="cityChange"
                class="szdSelect"
                placeholder="请选择市"
              >
                <el-option
                  v-for="(item, _city) in city"
                  :key="item.value"
                  :label="item.label"
                  :value="_city"
                />
              </el-select>
            </el-form-item>
            <!-- <el-form-item
              label="蒜瓣客户码"
              prop="customerId"
            >
              <el-input
                v-model="ruleForm.customerId"
                placeholder="请输入蒜瓣客户码"
                type="text"
              >
                <el-button
                  slot="append"
                  @click="sub_UserId(ruleForm.customerId, userInfo_get.customerId? 'JB': 'BD')"
                  type="text"
                >
                  {{ userInfo_get.customerId ? '解绑':'绑定' }}
                </el-button>
              </el-input>
            </el-form-item>
            <el-form-item
              label="蒜瓣客户名称"
              prop="customerName"
            >
              <el-input
                v-model="ruleForm.customerName"
                placeholder="绑定后自动生成蒜瓣客户名称"
                readonly
                type="text"
              />
            </el-form-item> -->

            <el-form-item label="公司名称">
              <el-input
                v-model="ruleForm.companyName"
                placeholder="请输入公司名称"
              />
            </el-form-item>
            <el-form-item label="公司性质">
              <el-select
                v-model="ruleForm.companyType"
                @change="cityTypeChange"
                placeholder="请选择公司性质"
              >
                <el-option
                  v-for="(qyxz, _qyxz) in qyxzArr"
                  :key="_qyxz"
                  :label="qyxz.name"
                  :value="_qyxz"
                />
              </el-select>
            </el-form-item>
            <el-form-item label="行业">
              <el-select
                v-model="ruleForm.industry"
                @change="industryChange"
                placeholder="请选择行业"
              >
                <el-option
                  v-for="(hy, _hy) in hyArr"
                  :key="_hy"
                  :label="hy.name"
                  :value="_hy"
                />
              </el-select>
            </el-form-item>
            <el-button
              @click="submitForm('ruleForm')"
              type="danger"
              class="personSubmit"
            >
              提交
            </el-button>
          </el-form>
        </el-main>
      </el-container>

      <el-dialog
        :visible.sync="passwordVisible"
        :close-on-click-modal="false"
        custom-class="modifyPsdDialog"
        append-to-body
        width="400px"
      >
        <div slot="title">
          <div class="font-size-20 font-weight-600">
            修改密码
          </div>
          <div class="font-size-16 margin-top-10">
            系统初始密码为：123456
          </div>
        </div>
        <el-form
          ref="modifyPsdForm"
          :model="modifyPsdForm"
          :rules="modifyPsdRules"
        >
          <el-form-item prop="curPass">
            <el-input
              v-model="modifyPsdForm.curPass"
              type="password"
              show-password
              placeholder="当前密码"
              autocomplete="off"
            />
          </el-form-item>
          <el-form-item prop="newPass">
            <el-input
              v-model="modifyPsdForm.newPass"
              type="password"
              show-password
              placeholder="新密码"
              autocomplete="off"
            />
          </el-form-item>
          <el-form-item prop="surePass">
            <el-input
              v-model="modifyPsdForm.surePass"
              type="password"
              show-password
              placeholder="确认密码"
              autocomplete="off"
            />
          </el-form-item>
        </el-form>
        <div
          slot="footer"
          class="dialog-footer"
        >
          <el-button
            @click="modifyPsdSubmit('modifyPsdForm')"
            type="danger"
          >
            确 定
          </el-button>
          <el-button @click="passwordVisible = false">
            取 消
          </el-button>
        </div>
      </el-dialog>
      <el-dialog
        :close-on-click-modal="false"
        :visible.sync="phoneVisible"
        custom-class="modifyPhoneDialog"
        append-to-body
        width="400px"
      >
        <div slot="title">
          <div class="font-size-20 font-weight-600">
            更换手机号
          </div>
        </div>
        <el-form
          ref="modifyPhoneForm"
          :model="modifyPhoneForm"
          :rules="modifyPhoneRules"
        >
          <el-form-item prop="phone">
            <el-input
              v-model="modifyPhoneForm.phone"
              placeholder="新手机号"
              type="phone"
              class="input-with-select"
            />
          </el-form-item>
          <el-form-item prop="code">
            <el-input
              v-model="modifyPhoneForm.code"
              class="regPhone"
              placeholder="请输入验证码"
              autocomplete="off"
            >
              <el-button
                slot="suffix"
                :disabled="auth_timeEditPhone!=0"
                @click="getCode(modifyPhoneForm.phone, ruleForm.phone, 'auth_timeEditPhone')"
                type="text"
              >
                {{ auth_timeEditPhone == 0?"获取验证码":`${auth_timeEditPhone}s重新发送` }}
              </el-button>
            </el-input>
          </el-form-item>
        </el-form>
        <div
          slot="footer"
          class="dialog-footer"
        >
          <el-button
            @click="modifyPhoneSubmit('modifyPhoneForm')"
            type="danger"
          >
            确 定
          </el-button>
          <el-button @click="phoneVisible = false">
            取 消
          </el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import areac from '@/assets/js/areac';
import rulesJS from '@/util/rulesUtil';
import { setStorage, getStorage } from '@/util/util';
import { getToken, removeToken } from '@/util/auth';
import Header from '@/components/Header';
import {
  POST_SUB_USER_INFO, POST_EDIT_PASSWORD, POST_EDIT_PHONE, POST_RANDOM, POST_USER_INFO,
} from '@/pages/index/actions/api';
import { editPhoto, getBindCustomer, getUnBindCustomer } from '@/pages/index/actions/actions';

export default {
  name: 'ModifyInfo',
  components: {
    Header,
  },
  data() {
    const validateNewPass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        callback();
      }
    };
    const validateSurePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.modifyPsdForm.newPass) {
        callback(new Error('两次输入的密码不一致'));
      } else {
        callback();
      }
    };
    return {
      photoShow: false, // 鼠标移入头像，显示修改头像的样式
      province: [], // 三联查询的省级
      city: [], // 三联查询的市级
      qyxzArr: [
        {
          name: '央企',
          code: 'YQ',
        },
        {
          name: '国有企业',
          code: 'GYQY',
        },
        {
          name: '外资',
          code: 'WZ',
        },
        {
          name: '民营',
          code: 'MY',
        },
        {
          name: '上市公司',
          code: 'SSGZ',
        },
        {
          name: '军工',
          code: 'JG',
        },
        {
          name: '未知',
          code: 'unknown',
        },
      ], // 企业性质
      hyArr: [
        {
          name: '互联网/IT',
          code: 'HLW',
        },
        {
          name: '金融',
          code: 'JR',
        },
        {
          name: '房地产/建筑',
          code: 'FDC',
        },
        {
          name: '商业服务',
          code: 'SYFW',
        },
        {
          name: '贸易/批发/零售',
          code: 'MY',
        },
        {
          name: '教育/艺术',
          code: 'JY',
        },
        {
          name: '生产制造业',
          code: 'SCZZY',
        },
        {
          name: '文化/传媒/娱乐',
          code: 'WH',
        },
        {
          name: '其他',
          code: 'QT',
        },
      ], // 行业
      auth_timeEditPhone: 0, // 修改手机号的验证码倒计时
      modifyPsdForm: {
        curPass: '',
        newPass: '',
        surePass: '',
      },
      modifyPsdRules: {
        curPass: [{ validator: rulesJS.rulesLoginPassword, trigger: 'blur' }],
        newPass: [{ validator: validateNewPass, trigger: 'blur' }],
        surePass: [{ validator: validateSurePass, trigger: 'blur' }],
      },
      passwordVisible: false,
      selectPrephone: '1',
      phoneVisible: false,
      modifyPhoneForm: {
        phone: '',
        code: '',
      },
      modifyPhoneRules: {
        phone: [{ validator: rulesJS.validatePhone, trigger: 'blur' }],
        code: [{ validator: rulesJS.rulesPhoneCode, trigger: 'blur' }],
      },
      ruleForm: {
        name: '',
        password: '1111',
        email: '',
        phone: '',
        companyName: '',
        customerName: '', // 蒜瓣客户名称
        customerId: '', // 蒜瓣客户码
        companyType: '',
        companyTypeCode: '',
        provinceValue: '',
        provinceCode: '',
        cityValue: '',
        cityCode: '',
        industry: '',
        industryCode: '',
      },
      rules: {
        name: [{ validator: rulesJS.validateName, trigger: 'blur' }],
        email: [{ validator: rulesJS.rulesRegEmail, trigger: 'blur' }],
      },
      value: '',
      // 用于改变Input类型
      passw: 'password',
      uploadUrl: '/jepaas/document/file',
      token: {
        jepaasAuthorization: getToken(),
        'Platform-Agent': 'AppleWebKit/537.36 (KHTML, like Gecko)',
      },
      uploadData: {},
      imageUrl: '',
      userInfo_get: {}, // 当前缓存的用户信息
    };
  },
  created() {
    this.getCity();
    this.replaceForm(); // 初始化替换页面表单对应的属性
    if (getToken()) {
      this.userInfo_get = JSON.parse(getStorage('local', 'userInfo'));
      const photoArr = this.userInfo_get.photo && this.userInfo_get.photo.split('*');
      // eslint-disable-next-line prefer-destructuring
      photoArr && photoArr.length > 1 && (this.userInfo_get.photo = photoArr[1]);
      this.imageUrl = this.userInfo_get.photo ? `/jepaas/document/preview?fileKey=${this.userInfo_get.photo}` : '';
    }
  },
  methods: {
    // 绑定蒜瓣用户
    sub_UserId(Id, type) {
      if (type == 'BD') {
        //
        if (!Id) return this.$message.error('请输入您的蒜瓣客户码再进行绑定');
        getBindCustomer({ userId: this.userInfo_get.userId, customerId: Id }).then((res) => {
          if (!res.success) return this.$message.error(`${res.message}`);
          this.$message.success('绑定成功!');
          this.ruleForm.customerName = res.obj; // 绑定蒜瓣客户名称
          this.getUserInfo('noMessage');
        });
      } else {
        getUnBindCustomer({ userId: this.userInfo_get.userId, customerId: Id }).then((res) => {
          if (!res.success) return this.$message.error(`${res.message}`);
          this.$message.success('解绑成功!');
          this.ruleForm.customerName = ''; // 绑定蒜瓣客户名称
          this.ruleForm.customerId = '';
          this.getUserInfo('noMessage');
        });
      }
    },
    // 头像移入移出事件
    mouseLeavePhoto() {
      this.photoShow = false;
    },
    mouseOverPhoto() {
      this.photoShow = true;
    },
    // 获取用户登录信息
    loadUserInfoAgain() {
      this.$ajax({
        url: POST_USER_INFO,
      }).then((datas) => {
        if (!datas.data.success) {
          if (datas.data.errorCode == '9101') {
            removeToken();
            return this.$message({
              message: '用户信息已失效，请重新登录！',
              type: 'error',
            });
          }
          return this.$message({
            message: `${datas.data.message}`,
            type: 'error',
          });
        }
        const photoArr = datas.data.obj.photo.split('*');
        // eslint-disable-next-line prefer-destructuring
        photoArr && (datas.data.obj.photo = photoArr[1]);
        this.$store.commit('getUserinfo', datas.data.obj);
        setStorage('local', 'userInfo', datas.data.obj);
        this.$refs.editUserInfo.loginSuccess();
        setTimeout(() => {
          setStorage('local', 'jepaasStore', this.$store.state);
        });
      });
    },
    // 替换当前的页面属性
    replaceForm() {
      this.ruleForm = {
        name: this.$store.state.userInfo.userName || '',
        password: '11111',
        email: this.$store.state.userInfo.email || '',
        phone: this.$store.state.userInfo.phone || '',
        customerName: this.$store.state.userInfo.customerName || '',
        customerId: this.$store.state.userInfo.customerId || '',
        companyName: this.$store.state.userInfo.gsmc || '',
        companyType: this.$store.state.userInfo.qyxzName || '',
        provinceValue: this.$store.state.userInfo.shengName || '',
        provinceCode: this.$store.state.userInfo.shengCode || '',
        cityValue: this.$store.state.userInfo.shiName || '',
        cityCode: this.$store.state.userInfo.shiCode || '',
        industry: this.$store.state.userInfo.sshyName || '',
      };
    },
    // 获取省份数据
    getCity() {
      this.province = areac.data.filter(item => [
        item.value,
        item.label,
      ]);
    },
    // 点击选中省
    provinceChange(val) {
      this.ruleForm.cityValue = '';
      this.ruleForm.countyValue = '';
      this.ruleForm.cityCode = '';
      this.ruleForm.areaCode = '';
      this.city = this.province[val].children;
      this.ruleForm.provinceValue = this.province[val].label;
      this.ruleForm.provinceCode = this.province[val].value;
    },
    // 选中市
    cityChange(val) {
      this.ruleForm.cityValue = this.city[val].label;
      this.ruleForm.cityCode = this.city[val].value;
    },
    // 企业性质选中
    cityTypeChange(val) {
      this.ruleForm.companyType = this.qyxzArr[val].name;
      this.ruleForm.companyTypeCode = this.qyxzArr[val].code;
    },
    // 行业选中
    industryChange(val) {
      this.ruleForm.industry = this.hyArr[val].name;
      this.ruleForm.industryCode = this.hyArr[val].code;
    },
    // 密码的隐藏和显示
    /*    showPass() {
      //点击图标是密码隐藏或显示
      if (this.passw == "text") {
        this.passw = "password";
      } else {
        this.passw = "text";
      }
    }, */
    // 修改密码
    modifyPsd() {
      this.passwordVisible = true;
    },
    // 修改手机号
    modifyPhoneSubmit(ref) {
      const changePhone = this.modifyPhoneForm.phone;
      this.$refs[ref].validate((valid) => {
        if (valid) {
          this.$ajax({
            url: POST_EDIT_PHONE,
            data: {
              oldPhone: this.ruleForm.phone,
              newPhone: this.modifyPhoneForm.phone,
              codeDomo: this.modifyPhoneForm.code,
            },
          }).then((obj) => {
            const { data } = obj;
            if (data.errorCode) {
              this.$message({
                message: `${data.message}`,
                type: 'error',
              });
              return;
            }
            this.$refs[ref].resetFields(); // 清空验证规则
            this.phoneVisible = false; // 关闭弹出框
            this.ruleForm.phone = changePhone; // 手机号动态更改
            this.getUserInfo();
            this.$refs.editUserInfo.loginSuccess(); // 重新刷新用户的信息
          });
        }
      });
    },
    // 修改手机号
    modifyPhone() {
      this.phoneVisible = true;
    },
    // 修改密码
    modifyPsdSubmit(ref) {
      this.$refs[ref].validate((valid) => {
        if (valid) {
          this.$ajax({
            url: POST_EDIT_PASSWORD,
            data: {
              phone: this.ruleForm.phone,
              oldPassword: this.modifyPsdForm.curPass,
              newPassword: this.modifyPsdForm.newPass,
            },
          }).then((obj) => {
            const { data } = obj;
            if (!data.success) {
              return this.$message({
                message: `${data.message}`,
                type: 'error',
              });
            }
            this.$message({
              message: '修改密码成功!',
              type: 'success',
            });
            this.$refs[ref].resetFields(); // 清空验证规则
            this.passwordVisible = false; // 关闭弹出框
          });
        }
      });
    },
    // 修改头像
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
      editPhoto({
        fileKey: res.obj[0].fileKey,
      }).then((result) => {
        if (!result.success) {
          return this.$message({
            type: 'error',
            message: '上传失败',
          });
        }
        this.$message({
          type: 'success',
          message: '上传成功',
        });
        this.loadUserInfoAgain();
      });
    },
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        return this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      this.uploadData.files = file;
      this.uploadData.metadataStr = '{ uploadType: \'TEMP\' }';
      this.uploadData.uploadFieldName = 'files';
      this.uploadData.uploadableFields = 'files';
      this.uploadData.fileTypes = '*';
      this.uploadData.jeFileType = 'PROJECT';
    },
    // 获取验证码
    getCode(phone, oldPhone, status) {
      if (phone == oldPhone) {
        this.$message({
          message: '手机号和之前相同',
          type: 'error',
        });
        return;
      }
      let errMsg;
      // 登录的正则判断
      this.$refs.modifyPhoneForm.validateField('phone', (error) => {
        if (error) {
          errMsg = error;
        }
      });
      // 不满足任何一项全部返回
      if (!phone || this[status] != 0 || errMsg) {
        return;
      }
      this.$ajax({
        url: POST_RANDOM,
        data: {
          phone,
          oldPhone,
        },
      }).then((obj) => {
        const { data } = obj;
        if (data.errorCode) {
          this.$message({
            message: `${data.message}`,
            type: 'error',
          });
          return;
        }
        this.$message({
          message: '验证码已发送!',
          type: 'success',
        });
        this[status] = 59;
        const auth_timer = setInterval(() => {
          this[status]--;
          if (this[status] <= 0) {
            clearInterval(auth_timer);
          }
        }, 1000);
      });
    },
    // 获取用户登录信息
    getUserInfo(noMessage) {
      this.$ajax({
        url: POST_USER_INFO,
        data: {},
      }).then((datas) => {
        if (datas.data.success) {
          const photoArr = datas.data.obj.photo.split('*');
          // eslint-disable-next-line prefer-destructuring
          photoArr && (datas.data.obj.photo = photoArr[1]);
          this.$store.commit('getUserinfo', datas.data.obj);
          setStorage('local', 'userInfo', datas.data.obj);
          this.userInfo_get = datas.data.obj;
          !noMessage && this.$message({
            message: '信息提交成功',
            type: 'success',
          });
          this.$refs.editUserInfo.loginSuccess();
          setTimeout(() => {
            setStorage('local', 'jepaasStore', this.$store.state);
          });
        } else {
          this.$message({
            message: `${datas.data.message}`,
            type: 'error',
          });
        }
      });
    },
    // 提交个人信息
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$ajax({
            url: POST_SUB_USER_INFO,
            data: {
              userId: this.$store.state.userInfo.userId,
              customerId: this.ruleForm.customerId || '',
              name: this.ruleForm.name,
              email: this.ruleForm.email,
              shengCode: this.ruleForm.provinceCode,
              shengName: this.ruleForm.provinceValue,
              shiCode: this.ruleForm.cityCode,
              shiName: this.ruleForm.cityValue,
              qyxzName: this.ruleForm.companyType,
              qyxzCode: this.ruleForm.companyTypeCode,
              gsmc: this.ruleForm.companyName,
              sshyName: this.ruleForm.industry,
              sshyCode: this.ruleForm.industryCode,
            },
          }).then((obj) => {
            const { data } = obj;
            if (data.errorCode) {
              this.$message({
                message: `${data.message}`,
                type: 'error',
              });
              return;
            }
            this.getUserInfo();
          });
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
  @import '../../style/shop/util';
</style>
<style lang="less">
body,
html {
  background-color: #fff;
}
.je-modifyinfo {
  position: relative;
  text-align: initial;
  .box_top {
    width: 100%;
    height: 80px;
    background: linear-gradient(
      270deg,
      rgba(252, 103, 81, 1) 0%,
      rgba(246, 71, 77, 1) 100%
    );
  }
  .modifyinfo {
    position: absolute;
    width: 1150px;
    top: 80px;
    left: 50%;
    transform: translateX(-50%);
    padding-top: 40px;

    .cline {
      position: absolute;
      top: 500px;
      width: 1150px;
      left: 50%;
      transform: translateX(-50%);
      height: 2px;
      background: rgba(245, 245, 245, 1);
    }
    .el-aside {
      width: 150px !important;
      text-align: right !important;
      position: relative;
      .headerInfo {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        overflow: hidden;
        display: inline-block;
        background-color: rgb(244,152,0);
        color: #fff;
        line-height: 100px;
        text-align: center;
        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }
      .mdyPhoto {
        position: absolute;
        top: 0;
        right: 0;
        text-align: center;
        width: 100px;
        height: 100px;
        line-height: 100px;
        border-radius: 50%;
        // background: rgba(38, 38, 38, 0.6);
        font-size: 16px;
        cursor: pointer;
        transition: all .2s ease-in;
        color: #fff;
        &:hover {
          background: rgba(38, 38, 38, 0.6);
        }
      }
      .nameInfo {
        display: inline-block;
        text-align: center;
        width: 100px;
        height: 30px;
        font-size: 22px;
        font-weight: 400;
        color: rgba(63, 63, 63, 1);
        line-height: 30px;
      }
      .menulist {
        height: 33px;
        font-size: 24px;
        font-weight: 500;
        color: rgba(63, 63, 63, 1);
        line-height: 33px;
        padding: 10px 0;
      }
    }
    .tips {
      font-size: 20px;
      font-weight: 400;
      color: rgba(63, 63, 63, 1);
      margin-bottom: 40px;
    }
    .regPhone {
      .el-input__suffix {
        top: 50%;
        transform: translateY(-50%);
      }
      .el-input__inner {
        padding-right: 100px;
      }
    }
    .personSubmit {
      width: 500px;
      margin-left: 100px;
      margin-top: 20px;
    }
    .pass {
      .el-input__inner {
        border: 0;
      }
      .el-input__suffix {
        right: 160px;
        transform: inherit !important;
      }
    }
    .el-form-item {
      margin-bottom: 31px;
    }
    .info-ruleForm {
      .el-form-item {
        width: 780px;
      }
      .el-input__inner {
        width: 500px;
      }
      .el-input-group__append {
        background-color: #fff;
        border-color: #fff;
        width: 100px;
        text-align: center;
        button.el-button {
          color: #409eff;
          &:hover {
            color: #7fb4eb;
          }
        }
      }
    }

    .szcsitem {
      margin-bottom: 102px;
    }
    .szdSelect {
      width: 210px;
      .el-input__inner {
        width: 100%;
      }
      &:last-child {
        margin-left: 80px;
      }
    }
  }
}

.modifyPhoneDialog,
.modifyPsdDialog {
  .input-with-select .el-input-group__prepend {
    background-color: #fff;
    width: 88px;
  }
  .el-dialog__body {
    padding-top: 5px!important;
    padding-bottom: 10px!important;
  }
  .el-dialog__footer {
    text-align: center;
    .el-button {
      width: 120px;
    }
  }
}
</style>
